import React, { useState, useEffect } from 'react';
// import FeedbackBarGraph from '../components/graphs/DoubleSidedBarGraph'; // Assuming the path here. Adjust if necessary.
import { getClustersWithSentiment } from '../api';
import Hero from '../components/Hero';
import DoubleSidedBarGraph from '../components/graphs/DoubleSidedBarGraph';

const CATEGORYNAME = "Deodorants"

function Qual2() {
    // States
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [sentiment, setSentiment] = useState("positive");  // Default to positive. Change if needed.

  useEffect(() => {
    async function fetchData() {
      try {
        // Replace with actual parameters if necessary
        const result = await getClustersWithSentiment(
          { category_name: CATEGORYNAME}
        );
        
        setData(result);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchData();
}, []);  // Empty dependency array means it will run once when the component mounts
  console.log(data);
  if (loading) {
      return (
        // A simple spinner with Tailwind CSS
        <div className="flex justify-center items-center h-screen">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
        </div>
      );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
      <div>
        <Hero title={CATEGORYNAME} />
        <DoubleSidedBarGraph data={data} numOfPoints={20} />
      </div>
  );
}

export default Qual2;
