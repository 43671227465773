const BASE_URL = process.env.REACT_APP_API_BASE_URL;

console.log(BASE_URL);


async function fetchFromAPI(endpoint, queryParams = {}) {
  const queryString = new URLSearchParams(queryParams).toString();
  console.log(queryString);
  const response = await fetch(`${BASE_URL}${endpoint}?${queryString}`, {
    headers: {
      'Authorization': `Token ${process.env.REACT_APP_API_KEY}`
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return await response.json();
}


// Fetch category stats by category_name
export async function getCategoryStats(category_name) {
  const response = await fetchFromAPI("/voc/quantitative/category_stats/", { category_name });
  const data = await response;
  

  const formattedData = [
    { "name": "No. of Brands", "value": data.brand_count },
    { "name": "No. of Products", "value": data.product_count },
    { "name": "No. of Reviews", "value": data.review_count },
    { "name": "Cumm. Avg. Rating", "value": data.avg_rating.toFixed(2) },
    // { "name": "First Review Date", "value": data.first_review_date }
  ];

  return formattedData;
}



// Fetch brand stats within a category by category_name
export async function getBrandStatsInCategory(category_name) {
  return fetchFromAPI("/voc/quantitative/brand_stats/", { category_name });
}

// Fetch reviews per month by either category_name or brand
export async function getReviewsPerMonth({ category_name, brand }) {
  if (category_name && brand) {
    return fetchFromAPI("/voc/quantitative/reviews_per_month/", { category_name, brand });
  } else if (category_name) {
    return fetchFromAPI("/voc/quantitative/reviews_per_month/", { category_name });
  }
}

// Fetch monthly mentions for cluster by cluster_label and category_name
export async function getMonthlyMentionsForCluster({ cluster_label, category_name }) {
  return fetchFromAPI("/voc/quantitative/cluster_per_month/", { cluster_label, category_name });
}

// ... [rest of the previous code]

// Fetch a list of product listings based on various filters
export async function listProductListings({ 
    category_id, 
    category_name, 
    brand, 
    name, 
    listing_id, 
    min_rating, 
    max_rating 
}) {
    return fetchFromAPI("/voc/productlistings/list/", {
        category_id,
        category_name,
        brand,
        name,
        listing_id,
        min_rating,
        max_rating
    });
}

// Fetch a list of retailers
export async function listRetailers() {
    return fetchFromAPI("/voc/retailers/list/");
}

// Fetch a list of categories
export async function listCategories() {
    return fetchFromAPI("/voc/categories/list/");
}

// Fetch a list of reviews based on listing_id or retailer_review_id
export async function listReviews({ listing_id, retailer_review_id }) {
    return fetchFromAPI("/voc/reviews/list/", { listing_id, retailer_review_id });
}

// Fetch clusters by sentiment, category_name, and optionally brand_name and listing_id
export async function getClustersBySentiment({ category_name, sentiment, brand_name, listing_id }) {
  const params = {};

  if (category_name !== undefined && category_name !== null) {
      params.category_name = category_name;
  }

  if (sentiment !== undefined && sentiment !== null) {
      params.sentiment = sentiment;
  }

  if (brand_name !== undefined && brand_name !== null) {
      params.brand_name = brand_name;
  }

  if (listing_id !== undefined && listing_id !== null) {
      params.listing_id = listing_id;
  }

  return fetchFromAPI("/voc/qualitative/clusters_by_sentiment/", params);
}

export async function getClustersWithSentiment({ category_name, brand_name, listing_id }) {
  const params = {};

  if (category_name !== undefined && category_name !== null) {
      params.category_name = category_name;
  }

  if (brand_name !== undefined && brand_name !== null) {
      params.brand_name = brand_name;
  }

  if (listing_id !== undefined && listing_id !== null) {
      params.listing_id = listing_id;
  }

  return fetchFromAPI("/voc/qualitative/top_clusters", params);
}

export async function getUniqueAspectsForCluster({ cluster_label, category_name }) {
  return fetchFromAPI("/voc/qualitative/aspects_by_cluster", { cluster_label, category_name });
}


export async function getBrandAspectStats({ cluster_label, category_name }) {
  return fetchFromAPI("/voc/get_brand_aspect_stats/", { cluster_label, category_name });
}

