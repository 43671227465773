function SmallHeader({ heading, filters }) {
  return (
    <div>
      <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
        <h1 className="text-base font-semibold leading-7 text-gray-900">{heading}</h1>

        {filters && (
          <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
            {filters.map((filter, idx) => (
              <a key={idx} href="#" onClick={filter.handler} className={filter.isActive ? 'text-indigo-600' : 'text-gray-700'}>
                {filter.name}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default SmallHeader;
