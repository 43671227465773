import React from 'react';
import Plot from 'react-plotly.js';

import { capitalizeFirstLetter } from '../../utils'

const FeedbackBarGraph = ({ data, sentiment, numOfTopics }) => {
    const labels = data.map(item => item.label).slice(0, numOfTopics);
    const counts = data.map(item => item.aspect_count).slice(0, numOfTopics);

    const barColor = sentiment === "positive" ? 'green' : 'red';

    const chartData = [
        {
            x: labels,
            y: counts,
            type: 'bar',
            marker: {
                color: barColor
            }
        }
    ];

    const chartLayout = {
        title: `Top ${capitalizeFirstLetter(sentiment)} Topics`,
        xaxis: {
            title: 'Topics',
            tickangle: -45
        },
        yaxis: {
            title: 'Counts'
        },
        margin: {
            l: 50,
            r: 10,
            b: 100,
            t: 50
        }
    };

    return (
        <Plot
            data={chartData}
            layout={chartLayout}
            config={{
                displayModeBar: false,
                responsive: true,
                maintainAspectRatio: false
            }}
        />
    );
}

export default FeedbackBarGraph;
