import React, { useState } from 'react';
import Plot from 'react-plotly.js';

function LineChart({ data }) {
  const [view, setView] = useState('5 Years');

  const filteredData = view === '1 Year' ? data.filter(d => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return new Date(d.month) >= oneYearAgo;
  }) : data.filter(d => {
    const threeYearsAgo = new Date();
    threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 5);
    return new Date(d.month) >= threeYearsAgo;
  });
  console.log(filteredData)
  const plotData = [
    {
      x: filteredData.map(d => d.month),
      y: filteredData.map(d => d.reviews),
      type: 'scatter',
      mode: 'lines',
      marker: { color: 'blue' },
    }
  ];

  return (
    <div>
      <button onClick={() => setView('5 Years')}>5 Years</button>
      <button onClick={() => setView('1 Year')}>1 Year</button>

      <Plot
        data={plotData}
        config={{ displayModeBar: false }}
        layout={{ 
          title: 'Reviews per Month',
          xaxis: {
            tickformat: "%b '%y",
            showline: true
          },
          yaxis: {
            title: 'Number of Reviews',
            showline: true
          }
        }}
      />
    </div>
  );
}

export default LineChart;