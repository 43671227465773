import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Layout from './components/Layout';
import Quantitative from './pages/Quantitative';
import Qualitative from './pages/Qualitative';
import Qual2 from './pages/Qual2';
import Brands from './pages/Brands'
import TopicBreakdown from './pages/TopicBreakdown';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/quant" element={<Quantitative />} />
          <Route path="/qual" element={<Qualitative />} />
          <Route path="/qual2" element={<Qual2 />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/topic_breakdown/:label" element={<TopicBreakdown />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
