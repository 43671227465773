import React from 'react';
import Plot from 'react-plotly.js';

const TopicFrequencyGraph = ({ data }) => {
  const months = data.map(entry => entry.month);
  const totalCount = data.map(entry => entry.total_count);
  const positiveCount = data.map(entry => entry.positive_count);
  const negativeCount = data.map(entry => entry.negative_count);
  const neutralCount = data.map(entry => entry.neutral_count);

  return (
    <Plot
      config={{ displayModeBar: false }}
      data={[
        {
          x: months,
          y: negativeCount,
          fill: 'tozeroy',
          type: 'scatter',
          mode: 'none',
          name: 'Negative',
          stackgroup: 'one',
          fillcolor: 'red',
        },
        {
          x: months,
          y: neutralCount,
          fill: 'tonexty',
          type: 'scatter',
          mode: 'none',
          name: 'Neutral',
          stackgroup: 'one',
          fillcolor: 'yellow',
        },
        {
          x: months,
          y: positiveCount,
          fill: 'tonexty',
          type: 'scatter',
          mode: 'none',
          name: 'Positive',
          stackgroup: 'one',
          fillcolor: 'green',
        },
        {
          x: months,
          y: totalCount,
          type: 'scatter',
          mode: 'lines',
          name: 'Total Count',
          line: {
            color: 'blue',
          },
        },
      ]}
      layout={{
        title: 'Topic Mentions Per Month',
        showlegend: true,
      }}
    />
  );
};

export default TopicFrequencyGraph;
