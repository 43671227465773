import React from 'react';
import Plot from 'react-plotly.js';

function VerticalBarGraph({ data, numOfAspects }) {

  // const sortedData = data.sort((a, b) => b.count - a.count);

  const filteredData = data.slice(0, numOfAspects).reverse();

  const names = filteredData.map(item => item.processed_name);
  const positiveCounts = filteredData.map(item => item.positive_count);
  const negativeCounts = filteredData.map(item => item.negative_count);
  const neutralCounts = filteredData.map(item => item.neutral_count);

  const trace1 = {
    x: positiveCounts,
    y: names,
    name: 'Positive',
    orientation: 'h',
    type: 'bar',
    marker: {
      color: 'green'
    }
  };

  const trace2 = {
    x: neutralCounts,
    y: names,
    name: 'Neutral',
    orientation: 'h',
    type: 'bar',
    marker: {
      color: 'yellow'
    }
  };

  const trace3 = {
    x: negativeCounts, // Removed the negative sign transformation here
    y: names,
    name: 'Negative',
    orientation: 'h',
    type: 'bar',
    marker: {
      color: 'red'
    }
  };

  const layout = {
    barmode: 'stack', // Change to 'stack' so all bars stack on top of each other on the same side
    title: 'Words Used to Describe Topic',
    xaxis: {
      title: 'Count',
    },
    yaxis: {
      title: 'Processed Name',
      ticklen: 5, // Adjusting tick length for better visibility
      tickfont: {
        size: 10  // Adjusting font size to fit all labels
      }
    },
    margin: {
      l: 100,
      r: 10,
      b: 100, 
      t: 100
    }
  };

  return (
    <Plot
      config={{ displayModeBar: false }}
      data={[trace1, trace2, trace3]}
      layout={layout}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default VerticalBarGraph;
