import React from 'react';
import Plot from 'react-plotly.js';

import { useNavigate } from 'react-router-dom';

function DoubleSidedBarGraph({ data, numOfPoints }) {
    const dataToDisplay = data.slice(0, numOfPoints).reverse();
    const labels = dataToDisplay.map(item => item.label);
    const positiveCounts = dataToDisplay.map(item => item.positive_count);
    const negativeCounts = dataToDisplay.map(item => -item.negative_count);
    const ratioPercentages = dataToDisplay.map(item => 
      (item.negative_count !== 0) ? (item.positive_count / item.negative_count * 100).toFixed(2) : 100
    ); 

    const navigate = useNavigate();

    const handleBarClick = (data) => {
        const pointIndex = data.points[0].pointIndex;
        const label = labels[pointIndex];
        // Here, we navigate to a new page. Adjust the URL as needed.
        navigate(`/topic_breakdown/${label}`);
    };

    const annotations = labels.map((label, index) => ({
      xref: 'paper',
      yref: 'y',
      x: 1,  // adjust as needed for positioning
      y: index,
      text: `${ratioPercentages[index]}%`,
      showarrow: false,
      font: {
          color: 'black', // contrast color
          size: 12
      },
    }));

    return (
      <Plot
        config={{ displayModeBar: false}}
        data={[
          {
              type: 'bar',
              x: positiveCounts,
              y: labels,
              orientation: 'h',
              name: 'Positive',
              marker: { color: 'green' },
          },
          {
              type: 'bar',
              x: negativeCounts,
              y: labels,
              orientation: 'h',
              name: 'Negative',
              marker: { color: 'red' },
          },
        ]}
        layout={{
          margin: {
            l: 210,
            r: 100,
            b: 50,
            t: 50,
            pad: 4
          },
          title: 'Top Topics Mentioned By Users',
          barmode: 'relative',
          width: 1000,  // adjust as needed
          height: 800, // adjust as needed
          yaxis: {
              categoryorder: 'total ascending',
              title: 'Topics',
              tickvals: labels.map((_, index) => index),  // setting tickvals and ticktext to ensure all labels are shown
              ticktext: labels
          },
          xaxis: {
              title: 'Count',
          },
        }}
        onClick={handleBarClick}
        annotations={annotations}
      />
    );
}

export default DoubleSidedBarGraph;
