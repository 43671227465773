import React, { useState, useEffect } from 'react';
import FeedbackBarGraph from '../components/graphs/FeedbackBarGraph'; // Assuming the path here. Adjust if necessary.
import { getClustersBySentiment } from '../api';

function Qualitative() {
    // States
  const [positiveClusters, setPositiveClusters] = useState(null);
  const [negativeClusters, setNegativeClusters] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [sentiment, setSentiment] = useState("positive");  // Default to positive. Change if needed.

  useEffect(() => {
    async function fetchData() {
      try {
        // Replace with actual parameters if necessary
        const positiveResult = await getClustersBySentiment(
          { category_name: "Deodorants", sentiment: "positive" }
        );
        const negativeResult = await getClustersBySentiment(
          { category_name: "Deodorants", sentiment: "negative" }
        );
        setPositiveClusters(positiveResult);
        setNegativeClusters(negativeResult)
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchData();
}, []);  // Empty dependency array means it will run once when the component mounts

  if (loading) {
      return (
        // A simple spinner with Tailwind CSS
        <div className="flex justify-center items-center h-screen">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
        </div>
      );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
      <div>
        <FeedbackBarGraph data={positiveClusters} sentiment="positive" numOfTopics={20} />
        <div className="mb-10"></div>
        <FeedbackBarGraph data={negativeClusters} sentiment="negative" numOfTopics={20} />
      </div>
  );
}

export default Qualitative;
