import React from 'react';
import Plot from 'react-plotly.js';

function ScatterPlot({ data }) {
  // Extract necessary information from data
  const brandNames = data.map(item => item.brand);
  const reviewCounts = data.map(item => item.reviews_count);
  const avgRatings = data.map(item => item.avg_rating);

  // Generate an array of random colors
  const colors = Array.from({ length: data.length }, () => '#' + Math.floor(Math.random()*16777215).toString(16));

  // Calculate the median for vertical line placement
  const medianReview = (Math.max(...reviewCounts) + Math.min(...reviewCounts)) / 2;

  // Create Plotly trace
  const trace = {
    x: reviewCounts,
    y: avgRatings,
    mode: 'markers',
    type: 'scatter',
    text: [], // empty array to remove text labels
    hoverinfo: 'text+x+y',
    hovertext: brandNames,
    marker: {
      color: colors,
      size: 12
    }
  };

  // Create layout for quadrants
  const layout = {
    xaxis: {
      title: 'Review Count',
      showline: true,
      showgrid: false,       
      zeroline: false,  // No need to draw a zero line when we draw custom lines
      linecolor: 'black',
      linewidth: 1
    },
    yaxis: {
      title: 'Average Rating',
      showline: true,
      showgrid: true,
      zeroline: false,  // No need to draw a zero line when we draw custom lines
      linecolor: 'black',
      linewidth: 1
    },
    shapes: [
      // Add horizontal line
      {
        type: 'line',
        x0: medianReview,
        x1: medianReview,
        y0: Math.min(...avgRatings),
        y1: Math.max(...avgRatings),
        line: {
          color: 'grey',
          width: 2,
          dash: 'dash'
        }
      },
      // Add vertical line
      {
        type: 'line',
        x0: Math.min(...reviewCounts),
        x1: Math.max(...reviewCounts),
        y0: 3.5,
        y1: 3.5,
        line: {
          color: 'grey',
          width: 2,
          dash: 'dash'
        }
      },
    ],
    showlegend: false,
    annotations: brandNames.map((brand, i) => ({
      x: reviewCounts[i],
      y: avgRatings[i],
      xref: 'x',
      yref: 'y',
      text: brand,
      showarrow: false,
    }))
  };

  return (
    <div style={{ display: 'flex' }}>
      <Plot
        config={{ displayModeBar: false }}
        data={[trace]}
        layout={layout}
        style={{ width: '100%', height: '700px' }}
      />
      {/* <div style={{ width: '30%', padding: '20px' }}>
        <h4>Brand Legend</h4>
        <table style={{ width: '100%' }}>
          <tbody>
            {colors.map((color, index) => (
              <tr key={index}>
                <td style={{ backgroundColor: color, width: '20px', height: '20px' }}></td>
                <td>{brandNames[index]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
}

export default ScatterPlot;
