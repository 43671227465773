import React, { useState, useEffect } from 'react';
import Hero from '../components/Hero';
import LineChart from '../components/graphs/LineChart';
import StatsBar from '../components/StatsBar';

import { getCategoryStats, getReviewsPerMonth } from '../api';

const CATEGORYNAME = "Deodorants"

function Quantitative() {
  // States
  const [reviewsPerMonth, setReviewsPerMonth] = useState(null);
  const [categoryStats, setCategoryStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        // TODO: The selected category has to ultimately come from a some larger context management thing
        // Replace with actual parameters if necessary
        const reviewsPerMonthResult = await getReviewsPerMonth({ category_name: CATEGORYNAME });
        setReviewsPerMonth(reviewsPerMonthResult);

        const categoryStatsResult = await getCategoryStats(CATEGORYNAME)
        setCategoryStats(categoryStatsResult)

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchData();
  }, []);  // Empty dependency array means it will run once when the component mounts

  if (loading) {
    return (
      // A simple spinner with Tailwind CSS
      <div className="flex justify-center items-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Hero title={CATEGORYNAME} />
      <StatsBar stats={categoryStats} />
      <LineChart data={reviewsPerMonth} />
    </div>
  );
}

export default Quantitative;
