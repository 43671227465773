import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Hero from '../components/Hero';
import TopicFrequencyGraph from '../components/graphs/TopicFrequencyGraph';
import VerticalBarGraph from '../components/graphs/AspectBarGraph';
import SmallHeader from '../components/SmallHeader';

import { getMonthlyMentionsForCluster, getUniqueAspectsForCluster } from '../api';

const CATEGORYNAME = "Deodorants";


const TopicBreakdown = () => {

  const { label } = useParams();
  const cluster_label = label;

  const category_name = CATEGORYNAME;

  const [topicFrequencyData, setTopicData] = useState(null);
  const [aspectFrequencyData, setAspectData] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topicFrequncyResult = await getMonthlyMentionsForCluster({ cluster_label, category_name });
        setTopicData(topicFrequncyResult);

        const aspectFrequencyResult = await getUniqueAspectsForCluster({ cluster_label, category_name });
        setAspectData(aspectFrequencyResult);

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [cluster_label, category_name]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!topicFrequencyData) {
    return <div>No data available.</div>;
  }

  return (
    <div>
      <div className="mb-10">
        <Hero title={CATEGORYNAME} />
      </div>
      <div>
        <SmallHeader heading={`Topic Breakdown: ${label}`} />
      </div>
      <TopicFrequencyGraph 
        data={topicFrequencyData}
      />
      <VerticalBarGraph 
        data={aspectFrequencyData}
        numOfAspects={20}
      />
    </div>
  );
}

export default TopicBreakdown;
