import React, { useState, useEffect } from 'react';
import ScatterPlot from '../components/graphs/ScatterPlotGraph';  // Adjust the import path if necessary
import { getBrandStatsInCategory } from '../api';  // Adjust the import path if necessary

const CATEGORY_NAME = "Deodorants"; // Replace with the category you're interested in

function Brands() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        console.log(CATEGORY_NAME);
        const result = await getBrandStatsInCategory(CATEGORY_NAME);
        setData(result.slice(0, 20));
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    }

    fetchData();
  }, []);  // Empty dependency array means this will run once when the component mounts

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ScatterPlot data={data} />
    </div>
  );
}

export default Brands;
